
import Vue from 'vue'

export default Vue.extend({
  name: 'ListItem',
  props: {
    sideText: {
      type: String,
      required: true
    },
    mainText: {
      type: String,
      required: true
    },
    showChange: {
      type: Boolean,
      required: false
    }
  }
})
