
import Vue from 'vue'
import listItem from './ListItem.vue'
import dateDialog from './DateDialog.vue'
import photographerSelector from './PhotographerSelector.vue'
import rejectJobModal from './RejectJobModal.vue'
import {
  AimsCodeWithDescription,
  allJobTypes,
  deleteJobPhotographer,
  getAccountRef,
  JobSnap,
  updateAccount,
  updateJob
} from '@ht-lib/accounts-common'
import {
  Account,
  Job,
  JobAccount,
  JobAccountContact,
  JobStatus,
  JobTerm,
  PartialDeep,
  Photographer,
  PhotographerJobStatus,
  PhotographerLookup,
  PhotographerRole
} from '@ht-lib/accounts-models'
import { ContactVenue } from '@ht-vue/misc'
import { format, fromUnixTime, getUnixTime, parse } from 'date-fns'
import colors from 'quasar/src/utils/colors.js';import qFormat from 'quasar/src/utils/format.js';
import { ActivityType, addActivity } from '@ht-lib/activity-collection'
import { clone, isNumber, map, merge } from 'lodash'
import { checkActivity } from '../ts/job'

const { pad } = qFormat

interface JobTypeOption {
  label: string
  value: string
}

interface PhotographerLookupWithId extends PhotographerLookup {
  id: string
}

interface OnHoldColourId {
  id: string
  hex: string
}

export default Vue.extend({
  name: 'JobModal',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { ContactVenue, dateDialog, listItem, photographerSelector, rejectJobModal },
  props: {
    canEditCommission: {
      type: Boolean,
      required: true
    },
    canQc: {
      type: Boolean,
      required: true
    },
    canEditGeneral: {
      type: Boolean,
      required: true
    },
    canCancelJob: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      jobBeforeEdit: null as null | Job,
      show: false,
      jobSnap: null as null | JobSnap,
      job: null as null | Job,
      jobTypes: null as null | JobTypeOption[],
      showContactModal: false,
      showChangeTakeDate: false,
      showRejectModal: false,
      showAddPhotographer: false,
      formattedTakeDate: '',
      account: null as null | Account,
      accountBeforeEdit: null as null | Account,
      accountUnlisten: null as null | (() => void),
      types: null as null | AimsCodeWithDescription[],
      holdPalette: [
        { id: 'yellow', hex: colors.getPaletteColor('yellow-3') },
        { id: 'red', hex: colors.getPaletteColor('red-3') },
        { id: 'green', hex: colors.getPaletteColor('green-3') },
        { id: 'blue', hex: colors.getPaletteColor('blue-3') },
        { id: 'purple', hex: colors.getPaletteColor('purple-3') },
        { id: 'brown', hex: colors.getPaletteColor('brown-3') }
      ] as OnHoldColourId[],
      photographerJobRole: [
        { label: 'Photographer', value: PhotographerRole.PHOTOGRAPHER },
        { label: 'Assistant', value: PhotographerRole.ASSISTANT },
        { label: 'Absent', value: PhotographerRole.ABSENT }
      ]
    }
  },
  computed: {
    jobIsCompleted () {
      return this.job.status === JobStatus.COMPLETE
    },
    canUpdateUserViewing () {
      return !this.job.userViewing || (!this.job.onHold && !this.jobIsCompleted)
    },
    photographerMapValues (): Photographer[] {
      return Object.values(this.job.photographersMap)
    },
    showRightSide (): boolean {
      return this.job.market !== 'G'
    },
    isDataLinked (): string {
      return this.job.isDataLinked ? 'Yes' : 'No'
    },
    jobAccount (): JobAccount {
      return this.job.account
    },
    accountAddress (): string {
      return this.job.account.dispatchAddress?.address?.replace(/,\s/g, ',<br>') ?? 'No Address'
    },
    contact (): JobAccountContact {
      return this.job.account.contact ?? {
        id: '',
        name: 'No contact Set',
        email: '',
        phone: '',
        role: ''
      }
    },
    totalPeopleCommission (): number {
      const sum = this.photographerMapValues
        .reduce((sum, p) => {
          sum += p.commission
          return sum
        }, 0)
      return sum + (this.job.account.holder.commission || 0)
    },
    halfTerm (): JobTerm {
      if (this.job.account.halfTerm != null) {
        return this.formatTerm(this.job.account.halfTerm)
      }

      return { open: '', close: '' }
    },
    fullTerm (): JobTerm {
      if (this.job.account.fullTerm != null) {
        return this.formatTerm(this.job.account.fullTerm)
      }

      return { open: '', close: '' }
    },
    takeDate (): string {
      return format(this.job.takeDate, 'dd MMM yyyy')
    },
    committedDate (): string {
      return this.job.committedToLabUnixTimestamp ? format(fromUnixTime(this.job.committedToLabUnixTimestamp), 'dd MMM yyyy') : 'None Set'
    },
    aimsAddedDate (): string {
      return this.job.aimsNumAddedUnixTimestamp ? format(fromUnixTime(this.job.aimsNumAddedUnixTimestamp), 'dd MMM yyyy') : 'None Set'
    },
    preventSubmit (): boolean {
      return this.commissionInValid(this.job.accountCommissionWeb) || this.commissionInValid(this.job.accountCommissionMod)
    },
    commissionSet (): boolean {
      const allCommissionValues = [this.job.accountCommissionWeb, this.job.accountCommissionMod, this.job.account.holder.commission, ...map(this.photographerMapValues, x => x.commission)]
      return allCommissionValues.every(isNumber)
    },
    holdColorId (): string | undefined {
      const color = this.job.onHoldColorId ?? '-'
      return this.holdPalette.find(x => x.id === color)?.id
    }
  },
  beforeDestroy () {
    this.accountUnlisten?.()
  },
  methods: {
    updateJob (job: PartialDeep<Job>): void {
      this.job = clone(merge(this.job, job))
    },
    async holdColorSelected (id: string): Promise<void> {
      const work: Array<Promise<void>> = []
      if (this.job.onhold === false) {
        work.push(addActivity({
          contextId: this.jobSnap.id,
          userId: this.$auth.user.uid,
          message: 'Job put on hold',
          type: ActivityType.JOB,
          labels: ['jobHeld']
        }))
      }

      this.job.onHold = true
      this.job.onHoldColorId = id
      work.push(updateJob(this.jobSnap.id, { ...this.job, onHold: true, onHoldColorId: id }))

      await Promise.all(work)
      await this.close()
    },
    async saveAndUnhold (): Promise<void> {
      const work: Array<Promise<void>> = []
      if (this.job.onhold === true) {
        work.push(addActivity({
          contextId: this.jobSnap.id,
          userId: this.$auth.user.uid,
          message: 'Job taken off hold',
          type: ActivityType.JOB,
          labels: ['jobOffHeld']
        }))
      }

      this.job.onHold = false
      this.job.onHoldColorId = null
      work.push(updateJob(this.jobSnap.id, { onHold: false, onHoldColorId: null }))
      await Promise.all(work)
    },
    cancelContactVenue (): void {
      this.job.account.contact = clone(this.jobBeforeEdit.account.contact)
      this.job.account.dispatchAddress = clone(this.jobBeforeEdit.account.dispatchAddress)
      this.showContactModal = false
    },
    doAddPhotographer (): void {
      this.showAddPhotographer = true
    },
    addPhotographerToJob (photographer: PhotographerLookupWithId): void {
      const mapEntry: Photographer = {
        name: photographer.displayName,
        id: photographer.id,
        email: photographer.mail,
        code: photographer.photographerId,
        commission: 0,
        imageCount: 0,
        role: PhotographerRole.PHOTOGRAPHER,
        status: PhotographerJobStatus.COMPLETE
      }

      this.$set(this.job.photographersMap, photographer.id, mapEntry)
      this.showAddPhotographer = false
    },
    removePhotographer (photographer: Photographer): void {
      this.$q.dialog({
        message: `Are you sure you want to remove ${ photographer.name } from this job?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.$delete(this.job.photographersMap, photographer.id)
      })
    },
    doTakeDateChange (): void {
      this.formattedTakeDate = format(fromUnixTime(this.job.takeUnixTimestamp), 'yyyy/MM/dd')
      this.showChangeTakeDate = true
    },
    setTakeDate (val: string): void {
      const newUnixTimestamp = getUnixTime(parse(val, 'yyyy/MM/dd', new Date()))
      this.job.takeDate = fromUnixTime(newUnixTimestamp)
      this.job.takeUnixTimestamp = newUnixTimestamp
      this.showChangeTakeDate = false
    },
    reset (): void {
      this.job = clone(this.jobBeforeEdit)
    },
    async filterFn (val: string, update: (fn: () => void) => void) {
      const filteredTypes = this.types
        .filter(x => {
          if (typeof this.job.market === 'undefined' || typeof x.market === 'undefined') return true
          return this.job.market === x.market
        })
        .filter(x => {
          if (val !== '') {
            return x.id.toLowerCase().includes(val.toLowerCase()) || x.description.toLowerCase().includes(val.toLowerCase())
          }
          return true
        })
        .map(x => ({ label: x.description, value: x.id }) as JobTypeOption)

      update(() => {
        this.jobTypes.splice(0, this.jobTypes.length) // Clear array
        filteredTypes.forEach(x => this.jobTypes.push(x))
      })
    },
    updateCommissions (): void {
      this.$q.dialog({
        message: 'The Job Type has been changed. Would you like to update the MOD and WEB commissions?',
        cancel: 'No',
        ok: 'Yes',
        persistent: true
      }).onOk(() => {
        const jobType = this.types.find(t => t.id === this.job.jobType)
        this.job.accountCommissionMod = jobType.commissionToAccount.modOrders ?? 0
        this.job.accountCommissionWeb = jobType.commissionToAccount.webOrders ?? 0
      })
    },
    // Called in pages/Index.vue
    async open (job: JobSnap): Promise<void> {
      this.jobBeforeEdit = job.data()
      this.jobSnap = job
      this.job = job.data()

      console.log('Opening Job. ID: ', job.id, 'Data:', this.job)
      const typesP = this.types === null ? allJobTypes() : Promise.resolve(this.types)
      const work: Array<Promise<unknown>> = [
        typesP,
        this.updateUserViewing(),
        addActivity({
          contextId: this.jobSnap.id,
          userId: this.$auth.user.uid,
          message: 'Opened job worksheet',
          type: ActivityType.JOB,
          labels: ['jobWorksheetOpened']
        })
      ];

      [this.types] = await Promise.all(work)

      this.jobTypes = this.types
        .filter(x => (this.job.market ?? 'S') === x.market)
        .map(x => ({ label: x.description, value: x.id }))

      this.accountUnlisten?.()
      this.accountUnlisten = getAccountRef(this.job.accountId)
        .onSnapshot(snap => {
          this.account = snap.data()
          this.accountBeforeEdit = snap.data()

          if (!this.job.account.holder.code) {
            const code = this.account.accountHolderContactInfo?.photographerId || ''
            console.error('Found job with no account holder photographer code. Updating now. JobId:', this.jobSnap.id, 'New Code:', code)
            this.job.account.holder.code = code
            updateJob(this.jobSnap.id, { account: { holder: { code } } }).catch(console.error).finally(() => {
              this.show = true
            })
          } else {
            this.show = true
          }
        })
    },
    formatTerm (term: JobTerm): JobTerm {
      const fmrt = (d: string): string => format(parse(d, 'yyyy-MM-dd', new Date()), 'dd MMM yyy')
      return {
        close: fmrt(term.close),
        open: fmrt(term.open)
      }
    },
    getBGColour (canEdit: boolean): string {
      return canEdit ? 'yellow-1' : 'grey-3'
    },
    commissionInValid (comm: number): boolean {
      if (typeof comm !== 'number') return false
      const validAccountCommission = comm >= 0 && comm < 100
      const validCommissions = this.validPhotographerCommissions()
      return !validCommissions || !validAccountCommission
    },
    validPhotographerCommissions (): boolean {
      return !this.photographerMapValues.some(p => (p.commission < 0 || p.commission >= 100))
    },
    async close (): Promise<void> {
      await this.updateUserViewing({ reset: true })

      this.accountUnlisten?.()

      this.showRejectModal = false
      this.showContactModal = false
      this.show = false
    },
    tryParseValueToInt (key: 'accountCommissionWeb' | 'accountCommissionMod'): void {
      let value = this.job[key]

      if (typeof value === 'string') {
        console.log(`Found commission value that is a string. Key: ${ key }, Value: ${ value }`)
        value = parseFloat(value)

        if (isNaN(value)) {
          console.error(`Bad Value in "${ key }", value is a non parseable string. Value: "${ value }"`)
        } else {
          this.job[key] = value
        }
      }
    },
    async tryUpdateAccount (): Promise<void> {
      if (this.accountBeforeEdit?.warningText !== this.account?.warningText) {
        console.log('Detected change in account warning text. Updating account now')
        await updateAccount(this.job.accountId, {
          warningText: this.account.warningText,
          hasWarning: !!this.account.warningText
        })
      }
    },
    async startQcWithPrompt (): Promise<void> {
      if (this.jobIsCompleted) {
        this.$q.dialog({
          title: 'Job has been Completed',
          message: 'This job has already been completed, are you sure you wish to continue to QC?',
          cancel: true,
          persistent: true
        })
          .onOk(async () => { await this.startQc() })
      } else {
        await this.startQc()
      }
    },
    async startQc (): Promise<void> {
      await this.updateUserViewing({ force: true })
      if (this.job.status === JobStatus.READY_FOR_QC) {
        this.job.status = JobStatus.QC_IN_PROGRESS
        await updateJob(this.jobSnap.id, this.job)
      }

      await this.$router.push(`/qc/review/${ this.jobSnap.id }/grid`)
    },
    async saveJob (): Promise<void> {
      this.setAimsJobNumber()
      this.tryParseValueToInt('accountCommissionWeb')
      this.tryParseValueToInt('accountCommissionMod')

      const aimsSet = typeof this.job.aimsJobNumber === 'string' && this.job.aimsJobNumber.length === 4

      let status = this.job.status
      if (this.job.market !== 'G') {
        if (this.job.status === JobStatus.AWAITING_COMMISSION && this.commissionSet) {
          status = JobStatus.REQUIRES_AIMS_NO
        } else if (this.job.status === JobStatus.REQUIRES_AIMS_NO && aimsSet && this.commissionSet) {
          if (this.job.hasBeenQCed !== true) {
            status = JobStatus.READY_FOR_QC
          } else if (this.job.nexusImportStatus != null) {
            status = JobStatus.COMPLETE
          }

          this.job.aimsNumAddedUnixTimestamp = getUnixTime(new Date())
        }
      }

      await Promise.all(
        Object.keys(this.jobBeforeEdit.photographersMap)
          .filter(x => typeof this.job.photographersMap[x] === 'undefined')
          .map(async k => deleteJobPhotographer(this.jobSnap.id, k))
      )

      if (this.job.onHold) {
        await updateJob(this.jobSnap.id, { ...this.job })
        return this.close()
      }

      await Promise.all([
        updateJob(this.jobSnap.id, { ...this.job, status }),
        this.tryUpdateAccount(),
        checkActivity({
          jobBeforeEdit: this.jobBeforeEdit,
          job: this.job,
          jobId: this.jobSnap.id,
          userId: this.$auth.user.uid
        })
      ])

      await this.close()
    },
    setAimsJobNumber (): void {
      this.job.aimsJobNumber = (!this.job.aimsJobNumber || Number(this.job.aimsJobNumber) <= 0)
        ? ''
        : pad(this.job.aimsJobNumber, 4)
    },
    cancelJob (): void {
      this.$q.dialog({
        message: 'Are you sure you want to cancel this job?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        const update: Partial<Job> = { isCancelled: true }
        await Promise.all([
          updateJob(this.jobSnap.id, update),
          addActivity({
            contextId: this.jobSnap.id,
            userId: this.$auth.user.uid,
            message: 'Job Cancelled',
            type: ActivityType.JOB,
            labels: ['jobCancelled']
          })
        ])

        await this.close()
      })
    },
    reinstateJob (): void {
      this.$q.dialog({
        message: 'Are you sure you want to reinstate this job?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        const update: Partial<Job> = { isCancelled: false }
        await Promise.all([
          updateJob(this.jobSnap.id, update),
          addActivity({
            contextId: this.jobSnap.id,
            userId: this.$auth.user.uid,
            message: 'Job Reinstated',
            type: ActivityType.JOB,
            labels: ['jobReinstated']
          })
        ])

        await this.close()
      })
    },
    async updateUserViewing ({ reset = false, force = false } = {}): Promise<void> {
      const userViewing = {
        id: reset ? null : this.$auth.user.uid,
        name: reset ? null : this.$auth.user.name
      }

      console.log(Date.now(), reset, force, this.job.userViewing, userViewing.id, userViewing.name)
      if (this.job.userViewing?.id === userViewing.id && this.job.userViewing?.name === userViewing.name) {
        return
      }

      if (this.canUpdateUserViewing || force) {
        this.job.userViewing = userViewing
        await updateJob(this.jobSnap.id, { userViewing: this.job.userViewing })
      }
    }
  }
})
