
import { UserProfile } from '@ht-web/firebase-adapter'
import { JobSnap, updateBooking, updateJob } from '@ht-lib/accounts-common'
import { format, fromUnixTime } from 'date-fns'
import { BookingStatus, JobStatus } from '@ht-lib/accounts-models'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'RejectJobModal',
  props: {
    show: {
      type: Boolean
    },
    jobSnap: {
      type: Object as PropType<JobSnap>
    }
  },
  data () {
    return {
      rejectReason: ''
    }
  },
  methods: {
    async rejectJob () {
      const jobData = this.jobSnap.data()
      const accountHolder = await UserProfile.byId(jobData.account.holder.id).single()
      const takeDate = format(fromUnixTime(jobData.takeUnixTimestamp), 'dd/MMM/yy')
      window.open(`mailto:${ accountHolder.email }?subject=${ jobData.account.code } - ${ takeDate }&body=${ this.rejectReason }`)
      await Promise.all([
        updateJob(this.jobSnap.id, { status: JobStatus.UPLOAD_LOCKED }),
        updateBooking(jobData.bookingId, { status: BookingStatus.CREATED })
      ])
      this.$emit('input')
    }
  }
})
