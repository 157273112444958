import { render, staticRenderFns } from "./JobNotesIcon.vue?vue&type=template&id=83c021b4&scoped=true"
import script from "./JobNotesIcon.vue?vue&type=script&lang=ts"
export * from "./JobNotesIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83c021b4",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QTooltip});
