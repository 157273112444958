
import { photographerCollection } from '@ht-lib/accounts-common'
import { PhotographerLookup } from '@ht-lib/accounts-models'
import Vue from 'vue'

interface PhotographerLookupWithId extends PhotographerLookup {
  id: string
}

export default Vue.extend({
  name: 'PhotographerSelector',
  props: {
    show: {
      type: Boolean
    }
  },
  data () {
    return {
      terms: '',
      photographerSnap: null as null | (() => void),
      photographers: [] as PhotographerLookupWithId[]
    }
  },
  computed: {
    visiblePhotographers (): PhotographerLookupWithId[] {
      if (this.terms.length < 3) {
        return []
      }

      return this.photographers
        .filter(x => matchPhotographerByNameOrNumber(x, this.terms.toLowerCase()))
        .sort((a, b) => sortPhotographerByName(a, b))
    }
  },
  mounted (): void {
    this.photographerSnap = photographerCollection()
      .onSnapshot(p => {
        this.photographers = p.docs.map<PhotographerLookupWithId>(d => ({
          id: d.id,
          ...d.data()
        }))
      })
  },
  beforeDestroy (): void {
    this.photographerSnap?.()
  }
})

function matchPhotographerByNameOrNumber (photographer: PhotographerLookupWithId, terms: string): boolean {
  const matchName = photographer.displayName?.toLowerCase().includes(terms)
  const matchID = photographer.photographerId?.includes(terms)
  return matchName || matchID
}

function sortPhotographerByName (a: PhotographerLookupWithId, b: PhotographerLookupWithId): number {
  if (!a.displayName || !b.displayName) return -1
  const nameTokensA = a.displayName.split(' ')
  const lastnameA = nameTokensA[nameTokensA.length - 1]
  const nameTokensB = b.displayName.split(' ')
  const lastnameB = nameTokensB[nameTokensB.length - 1]
  if (lastnameA > lastnameB) return 1
  if (lastnameA < lastnameB) return -1

  const firstNameA = nameTokensA[0]
  const firstNameB = nameTokensB[0]

  if (firstNameA > firstNameB) return 1
  if (firstNameA < firstNameB) return -1

  return 0
}

