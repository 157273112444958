
import { format, fromUnixTime } from 'date-fns'
import { StackedText } from '@ht-vue/misc'
import {
  Job,
  JobStatus,
  jobStatusUserComplete,
  jobStatusUserToAction,
  UserProfile
} from '@ht-lib/accounts-models'
import { JobSnap, updateJob } from '@ht-lib/accounts-common'
import { partition, sortBy } from 'lodash'
import Vue, { PropType } from 'vue'
import { formatDate, fromUnixToDay } from '../../common'
import JobNotesIcon from '@/components/JobNotesIcon.vue'

interface JobWithData {
  id: string
  data: Job
  snapshot: JobSnap
}

export default Vue.extend({
  name: 'JobList',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { JobNotesIcon, StackedText },
  props: {
    jobs: {
      type: Array as PropType<JobSnap[]>,
      required: true
    },
    listName: {
      type: String,
      required: true
    },
    user: {
      type: Object as PropType<UserProfile>,
      required: true
    },
    canQc: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isComplete: {
      type: Boolean,
      required: true
    },
    detailedCounts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      actionStatuses: [] as JobStatus[]
    }
  },
  computed: {
    jobsWithData (): JobWithData[] {
      return this.jobs.map(x => ({ id: x.id, data: x.data(), snapshot: x }))
    },
    jobCounts (): Record<'heldJobs' | 'newJobs' | 'totalJobs', number> {
      const [heldJobs, newJobs] = partition(
        this.jobsWithData,
        x => x.data.onHold
      ).map(x => x.length)
      const totalJobs = this.jobs.length
      return { heldJobs, newJobs, totalJobs }
    },
    sortedJobs (): JobWithData[] {
      if (this.isComplete) {
        return sortBy(this.jobsWithData, x => x.data.takeUnixTimestamp)
      }

      return [...this.jobsWithData].sort((a, b) => {
        const dayDiff =
          fromUnixToDay(
            a.data.committedToLabUnixTimestamp || a.data.takeUnixTimestamp
          ) -
          fromUnixToDay(
            b.data.committedToLabUnixTimestamp || b.data.takeUnixTimestamp
          )
        const accountCodeDiff = a.data.account.code.localeCompare(
          b.data.account.code
        )
        return dayDiff - accountCodeDiff
      })
    }
  },
  created (): void {
    const roles = this.$auth.user.profile.roles ?? []
    this.actionStatuses = [
      ...jobStatusUserToAction(roles),
      ...jobStatusUserComplete(roles)
    ]
  },
  methods: {
    rowColour (job: Job): string {
      if (job.isCancelled) {
        return 'bg-red-3'
      }

      if (job.onHold) {
        const color = job.onHoldColorId ?? 'yellow'
        return `bg-${ color }-2`
      }

      return ''
    },
    userViewing (job: Job): string {
      return job.userViewing?.name || '-'
    },
    committedDate (job: Job): string {
      const timestamp = job.committedToLabUnixTimestamp
      return timestamp
        ? format(fromUnixTime(timestamp), 'dd MMM yyy')
        : 'Not set'
    },
    canReview (job: Job): boolean {
      return this.actionStatuses.some(x => job.status === x)
    },
    showQcButton (job: Job): boolean {
      return job.status === JobStatus.READY_FOR_QC && this.canQc
    },
    async startQc (jobId: string): Promise<void> {
      const update: Partial<Job> = {
        status: JobStatus.QC_IN_PROGRESS,
        userViewing: {
          id: this.$auth.user.uid,
          name: this.$auth.user.name
        }
      }
      await updateJob(jobId, update)
      await this.$router.push(`/qc/review/${ jobId }/grid`)
    },

    formatDate
  }
})
