
import Vue from 'vue'
import { getUnixTime, format, subQuarters } from 'date-fns'
import { jobsWithStatus } from '@ht-lib/accounts-common'
import { JobStatus, Job, WithId } from '@ht-lib/accounts-models'
import { formatDate } from '../../common'
import JobNotesIcon from '@/components/JobNotesIcon.vue'

const uncommittedStatuses: JobStatus[] = [
  JobStatus.UPLOAD_LOCKED,
  JobStatus.WAITING_FOR_PHOTOGRAPHERS
]

interface DateRange<T> {
  from: T
  to: T
}

export default Vue.extend({
  name: 'UncommittedJobsModal',
  components: { JobNotesIcon },
  data () {
    const now = Date.now()
    const minDate = format(subQuarters(now, 2), 'yyyy/MM/dd')
    const maxDate = format(now, 'yyyy/MM/dd')

    return {
      datePickerBoundaries: { minDate, maxDate },
      columns: [
        {
          name: 'account',
          label: 'Account',
          align: 'center',
          field: (row: WithId<Job>) => row.account.name,
          sortable: true
        },
        {
          name: 'accountCode',
          label: 'Account Code',
          align: 'center',
          field: (row: WithId<Job>) => row.account.code,
          sortable: true
        },
        {
          name: 'accountHolder',
          label: 'Account Holder',
          align: 'center',
          field: (row: WithId<Job>) => row.account.holder.name,
          sortable: true
        },
        {
          name: 'status',
          label: 'Status',
          align: 'center',
          field: (row: WithId<Job>) => row.status.toLowerCase(),
          style: 'text-transform: capitalize;',
          sortable: true
        },
        {
          name: 'jobType',
          label: 'Job Type',
          align: 'center',
          field: (row: WithId<Job>) => row.jobType,
          sortable: true
        },
        {
          name: 'takeDate',
          label: 'Take Date',
          align: 'center',
          field: (row: WithId<Job>) => row.takeDate,
          format: val => formatDate(val),
          sortable: true
        },
        {
          name: 'notes',
          label: 'Notes',
          align: 'center',
          field: (row: WithId<Job>) => row.notes,
          sortable: false
        }
      ],
      dateRange: null as null | DateRange<string>,
      dateRangeTemp: null as null | DateRange<string>,
      jobs: [] as Array<WithId<Job>>,
      filter: '',
      loading: false,
      datePickerShowing: false,
      scrollContainerStyle: 'padding: 0 16px 24px 16px;'
    }
  },
  computed: {
    dateRangeDates (): DateRange<Date> {
      if (this.dateRange === null) {
        return null
      }

      return { from: new Date(this.dateRange.from), to: new Date(this.dateRange.to) }
    }
  },
  methods: {
    show (): void {
      this.$refs.dialog.show()
    },

    hide (): void {
      this.$refs.dialog.hide()
    },

    async loadJobs (): Promise<void> {
      if (this.dateRangeDates === null) {
        return
      }

      this.loading = true

      try {
        const jobsSnapshot = await jobsWithStatus(uncommittedStatuses)
          .where(
            'takeUnixTimestamp',
            '>=',
            getUnixTime(this.dateRangeDates.from)
          )
          .where(
            'takeUnixTimestamp',
            '<=',
            getUnixTime(this.dateRangeDates.to)
          )
          .orderBy('takeUnixTimestamp')
          .get()

        this.jobs = jobsSnapshot.docs.map(x => ({ ...x.data(), id: x.id }))
      } finally {
        this.loading = false
      }
    },

    onDatePickerHide (): void {
      this.dateRangeTemp = this.dateRange
    },

    async onDatePickerSubmit (): Promise<void> {
      if (this.dateRange !== this.dateRangeTemp) {
        this.dateRange = this.dateRangeTemp
        await this.loadJobs()
      }
    },

    clearDates (): void {
      this.dateRangeTemp = null
    },

    dateOptions (date: Date): boolean {
      console.log(date)
      return date >= this.datePickerBoundaries.minDate && date <= this.datePickerBoundaries.maxDate
    },

    format,
    formatDate
  }
})
