
import Vue from 'vue'

export default Vue.extend({
  name: 'DateDialog',
  props: {
    show: {
      type: Boolean
    },
    value: {
      type: String
    }
  }
})
