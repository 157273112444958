import { render, staticRenderFns } from "./JobList.vue?vue&type=template&id=1aec0dde&lang=pug"
import script from "./JobList.vue?vue&type=script&lang=ts"
export * from "./JobList.vue?vue&type=script&lang=ts"
import style0 from "./JobList.vue?vue&type=style&index=0&id=1aec0dde&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip,QAvatar,QMarkupTable,QBtn,QSpinner});
