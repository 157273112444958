import { ActivityType, Job } from '@ht-lib/accounts-models'
import { addActivity } from '@ht-lib/activity-collection'

interface CheckActivityData {
  jobBeforeEdit: Job
  job: Job
  jobId: string
  userId: string
}
export function checkActivity ({ job, jobBeforeEdit, jobId, userId }: CheckActivityData): Array<Promise<void>> {
  const proms: Array<Promise<void>> = []
  if (jobBeforeEdit.aimsJobNumber !== job.aimsJobNumber) {
    proms.push(addActivity({
      contextId: jobId,
      userId: userId,
      message: `Changed AimsJobNumber from: '${ jobBeforeEdit.aimsJobNumber }' to: '${ job.aimsJobNumber }'`,
      type: ActivityType.JOB,
      labels: ['aimsJobNumberUpdated']
    }))
  }

  if (jobBeforeEdit.accountCommissionWeb !== job.accountCommissionWeb) {
    proms.push(addActivity({
      contextId: jobId,
      userId: userId,
      message: `Changed account commission WEB from: '${ jobBeforeEdit.accountCommissionWeb }' to: '${ job.accountCommissionWeb }'`,
      type: ActivityType.JOB,
      labels: ['accountCommissionUpdated']
    }))
  }

  if (jobBeforeEdit.accountCommissionMod !== job.accountCommissionMod) {
    proms.push(addActivity({
      contextId: jobId,
      userId: userId,
      message: `Changed account commission MOD from: '${ jobBeforeEdit.accountCommissionMod }' to: '${ job.accountCommissionMod }'`,
      type: ActivityType.JOB,
      labels: ['accountCommissionUpdated']
    }))
  }

  for (const id in job.photographersMap) {
    const after = job.photographersMap[id]
    const before = jobBeforeEdit.photographersMap[id]
    if (typeof before === 'undefined') {
      proms.push(addActivity({
        contextId: jobId,
        userId: userId,
        message: `Add Photographer ${ after.name }`,
        type: ActivityType.JOB,
        labels: ['photographerAdded']
      }))
    } else if (before.commission !== after.commission) {
      proms.push(addActivity({
        contextId: jobId,
        userId: userId,
        message: `Changed commission for ${ after.name } from: '${ before.commission }' to: '${ after.commission }'. PhotographerId: ${ after.id }`,
        type: ActivityType.JOB,
        labels: ['photographerCommissionUpdated']
      }))
    }
  }

  return proms
}
