
import Notify from 'quasar/src/plugins/Notify.js';
import { jobStatusUserComplete, jobStatusUserToAction, userHasRoles, UserRoles } from '@ht-lib/accounts-models'
import { isEqual, partition } from 'lodash'
import Vue from 'vue'
import JobModal from '../components/JobModal.vue'
import JobList from '../components/JobList.vue'
import { JobSnap, jobsWithStatus, searchJobs, splitAimsJobNumber } from '@ht-lib/accounts-common'
import { UserProfile, UserProfileWithData } from '@ht-web/firebase-adapter'
import UncommittedJobsModal from '@/components/UncommittedJobsModal.vue'

export default Vue.extend({
  name: 'Index',
  components: {
    JobList,
    JobModal
  },
  data () {
    return {
      toAction: [] as JobSnap[],
      completed: [] as JobSnap[],
      userSnap: null as null | (() => void),
      toActionSnap: null as null | (() => void),
      completeSnap: null as null | (() => void),
      userProfile: null as UserProfileWithData | null,
      loading: true,
      searchTerm: '',
      searchLoading: false,
      aimsJobNumber: ''
    }
  },
  computed: {
    jobsToAction (): JobSnap[] {
      if (this.searchTerm.length > 0) {
        return this.toAction
      }

      return this.toAction.filter(job => !job.data().isCancelled)
    },
    canEditCommission (): boolean {
      return userHasRoles(this.$auth.user.profile, [UserRoles.SOFTWARE_GODS, UserRoles.COMMISSION_ADMIN])
    },
    canQC (): boolean {
      return userHasRoles(this.$auth.user.profile, [UserRoles.SOFTWARE_GODS, UserRoles.QC_REVIEWER])
    },
    canEditGeneral (): boolean {
      return userHasRoles(this.$auth.user.profile, [UserRoles.SOFTWARE_GODS, UserRoles.COMMISSION_ADMIN, UserRoles.DATA_TEAM])
    },
    canCancelJob (): boolean {
      return userHasRoles(this.$auth.user.profile, [UserRoles.SOFTWARE_GODS, UserRoles.DATA_TEAM])
    },
    completedStatuses (): string[] {
      return jobStatusUserComplete(this.userProfile.roles)
    }
  },
  mounted () {
    this.userSnap = UserProfile.byId(this.$auth.user.uid)
      .listen(user => {
        const oldUser = this.userProfile
        this.userProfile = user
        if (!oldUser || !isEqual(this.userProfile.roles, oldUser.roles)) {
          this.setupSnapshots()
        }
      })
  },
  beforeDestroy () {
    this.cleanUpSnap()
    this.userSnap?.()
  },
  methods: {
    cleanUpSnap () {
      this.toActionSnap?.()
      this.completeSnap?.()
    },

    setupSnapshots () {
      this.cleanUpSnap()
      console.log('Updating Job snapshot')
      if (!this.userProfile) return

      const setupActionableJobs = () => {
        const toActionStatuses = jobStatusUserToAction(this.userProfile.roles)
        if (toActionStatuses.length === 0) {
          Notify.create('You do not have permission to see any jobs on this page')
          this.loading = false
          return
        }

        this.toActionSnap = jobsWithStatus(toActionStatuses)
          .onSnapshot((snap) => {
            console.log('To action docs loaded')
            this.toAction = snap.docs
            this.loading = false
          })
      }

      const setupCompleteJobs = () => {
        if (this.completedStatuses.length === 0) {
          return
        }

        this.completeSnap = jobsWithStatus(this.completedStatuses)
          .orderBy('takeUnixTimestamp', 'desc')
          .limit(20)
          .onSnapshot((snap) => {
            console.log('Completed docs loaded')
            this.completed = snap.docs
          })
      }

      setupActionableJobs()
      setupCompleteJobs()
    },

    openJobModal (job: JobSnap): void {
      console.log('this.$refs :', this.$refs)
      this.$refs.jobModal.open(job)
    },

    async nexusImages (): Promise<void> {
      await this.$router.push(`/nexus/${ this.aimsJobNumber }`)
    },

    showUncommittedJobsModal () {
      this.$q.dialog({
        component: UncommittedJobsModal,
        parent: this
      })
    }
  },
  watch: {
    searchTerm (): void {
      this.cleanUpSnap()
      console.log('Updated search term to ', this.searchTerm)
      if (!this.searchTerm) {
        this.aimsJobNumber = ''
        this.setupSnapshots()
        return
      }

      this.toActionSnap = searchJobs(this.searchTerm, (jobs) => {
        console.log('job search snapshot updated')
        const [complete, incomplete] = partition(jobs, x => this.completedStatuses.includes(x.data().status))
        this.completed = complete
        this.toAction = incomplete

        const aimsJobNumber = splitAimsJobNumber(this.searchTerm)
        if (jobs.length === 0 && aimsJobNumber.isComplete) {
          this.aimsJobNumber = `${ aimsJobNumber.accountCode }-${ aimsJobNumber.jobCode }`
        } else {
          this.aimsJobNumber = ''
        }
      })
    }
  }
})
